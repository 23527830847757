//=================================================
// Sizing
//=================================================

.min-w-100 {
	min-width: 100% !important;
}

.min-w-auto {
	min-width: auto !important;
}
