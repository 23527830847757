//=================================================
// Transition
//=================================================

/*=============================
// Transition all
=============================*/
.transition-all { 
    transition: all $transition-s ease;
    
    &-none {
        transition: none;
    }
}

/*=============================
// Transition opacity
=============================*/
.transition-opacity {
    transition: opacity $transition-s ease;
}

/*=============================
// Transition transform
=============================*/
.transition-transform {
    transition: transform $transition-s ease;
}
