//=================================================
// Transform
//=================================================

/*=============================
// Transform scale
=============================*/
.transform-scale {
    transform: scale(1.1);
}

/*=============================
// Transform scale hover
=============================*/
.transform-scale-h {
    display: inline-block;
    @extend .transition-all;
    transform: perspective(1px) scale(1) translateZ(0);
    backface-visibility: hidden;
    
    &:hover {
        transform: perspective(1px) scale(1.1) translateZ(0);
    }
    
    &-none {
        &:hover {
            transform: perspective(1px) scale(1) translateZ(0);
        }
    }
}
