//=================================================
// About
//=================================================

.about-img {
	.img {
		min-height: 400px;

		&.object-fit {
			&:before {
				padding-top: 50%;
			}
		}
	}
}
