//=================================================
// Examples
//=================================================

.ui {
    .widget-area {
        @media #{$media_lg} {
            margin-top: 0;
            margin-bottom: 5.63rem;
        }

        &:not(.mCS_no_scrollbar) {
            .mCSB_container {
                margin-right: 0.5em;
            }
        }
    }

    .site-footer {
        position: relative;
        z-index: 2;
        background-color: $body_background_color;
    }
}

.bd {
    &-example {
        width: 100%;
        position: relative;
        padding: $space_xs;
        background-color: $body_background_color;
        border: .06rem solid $border_color;

        &:not(.style-none) {
            @extend .style-default;
        }

        > .btn, > .btn-group, > .btn-toolbar {
            margin-top: 0.132rem;
            margin-bottom: 0.132rem;
        }

        > .form-control + .form-control {
            margin-top: 0.264rem;
        }

        > .dropdown-menu {
            &:first-child {
                position: static;
                display: block;
            }
        }

        &-modal {
            .modal {
                display: block;
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
                z-index: 1;
            }
        }

        &-border-utils {
            [class^=border] {
                display: inline-block;
                width: 5rem;
                height: 5rem;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
            }

            &-0 {
                [class^=border] {
                    border: 1px solid $border_color;
                }
            }
        }

        &:after {
            display: block;
            clear: both;
            content: "";
        }
    }

    &-placeholder-img {
        font-size: 1rem;
        text-anchor: middle;
        user-select: none;
        fill: $body_main_element_primary_color;

        text {
            font-family: $font_primary_bold;
            font-weight: bold;
            fill: $body_text_tertiary_color;
        }

        &-lg {
            font-size: 2.5rem;
        }
    }

    &-highlight {
        background-color: $gray-light;
        border: 1px solid rgba($gray-dark, 0.2);
        margin: -1px 0 -1px -1px;
    }

    &-callout {
        padding: $space_xs;
        margin-top: $space_md;
        margin-bottom: $space_md;
        border: 1px solid $border_color;
        border-left-width: .25rem;
        @extend .style-default;

        &-warning {
            border-left-color: $warning;
        }

        &-info {
            border-left-color: $info;
        }
    }
}

// Code snippets
.highlight {
    margin-bottom: $space_md !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    pre {
        margin-top: 0;
        padding-left: $space_xs;
        padding-right: $space_xs;
        background: $gray-light;

        code {
            font-size: 0.842rem;
        }
    }
}
