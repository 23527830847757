//=================================================
// Languages
//=================================================

.languages {
    .nav {
        font-size: $f_size_lg;
        .lang-item {
            margin-bottom: 0;
            line-height: 0.7;
            color: $body_text_tertiary_color;
            mix-blend-mode: difference;
            background: none;

            &:before, &:after {
                display: none;
            }

            &:first-child {
                margin-left: 0;
            }

            option {
                font-family: $font_secondary;
                color: #000;
            }

            &.active, &.current-lang {
                cursor: pointer;
                option {
                    &:hover {
                        opacity: 1 !important;
                        color: #000;
                    }
                }
            }
        }

        &.flex-column {
            .lang-item {
                margin-left: 0;
                margin-bottom: $space_xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.lang-position {
    position: absolute !important;
    width: auto !important;
    bottom: $space_lg;
}
