//=================================================
// Newsletter
//=================================================

.newsletter {
	text-align: center;

	.newsletter-description {
		padding-top: $space_lg;
		max-width: 506px;
		margin: auto;
	}

	.newsletter-shortcode {
		padding-top: $space_lg;
		max-width: 780px;
		margin: auto;

		form {
			position: relative;

			input {
				padding-right: 5.263rem;
			}

			.input-group-append {
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				right: 0;
			}

			&.newsletter-form {
				&.top-no-space {
					margin-top: -0.947rem;
				}

				.alert {
					margin-top: $space_xs;
					margin-bottom: 0;
				}
			}
		}
	}
}

.mc4wp-form {
	position: relative;

	input {
		padding-right: 5.263rem;
	}

	.input-group-append {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: 0;
	}

	.mc4wp-response {
		.mc4wp-alert {
			p {
				@extend .before;
				font-size: $f_size_df;
				margin-top: $space_xs;
			}
		}
	}
}
